import {
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputProps,
  InputRightAddon,
  InputRightElement,
} from "@chakra-ui/react";
import { RefObject, useEffect, useState } from "react";
import { useField } from "remix-validated-form";
import { FieldFooter } from "components/FieldFooter";

export type ValidatedInputProps = Omit<InputProps, "name"> & {
  formId?: string;
  name: string;
  label?: string | React.ReactNode;
  inputLeftAddon?: string | React.ReactNode;
  inputLeftAddonBg?: string;
  inputRightAddon?: string;
  inputLeftElement?: React.ReactNode;
  inputRightElement?: React.ReactNode;
  helper?: string;
  forwardRef?: RefObject<HTMLElement>;
  hideFocusedError?: boolean;
};

export default function ValidatedInput({
  formId,
  name,
  label,
  inputLeftAddon,
  inputLeftAddonBg,
  inputRightAddon,
  inputLeftElement,
  inputRightElement,
  helper,
  isRequired,
  forwardRef,
  autoComplete = "off",
  hideFocusedError = false,
  ...props
}: ValidatedInputProps) {
  // Get the error and input props from remix-validated-form
  const { error, getInputProps } = useField(name, { formId });
  const inputProps = getInputProps();

  // Track focus state to conditionally display error
  const [isFocused, setIsFocused] = useState(false);
  const [displayError, setDisplayError] = useState(error);

  // Function to manage focus state and merge handlers (useField)
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    if ("onFocus" in inputProps && typeof inputProps.onFocus === "function") inputProps.onFocus(e);
  };

  // Function to manage blur state and merge onBlur handlers (useField)
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if ("onBlur" in inputProps && typeof inputProps.onBlur === "function") inputProps.onBlur(e);
  };

  // Display the error based on focus state and hideFocusedError
  useEffect(() => {
    if (hideFocusedError && isFocused) setDisplayError(undefined);
    else setDisplayError(error);
  }, [error, hideFocusedError, isFocused]);

  return (
    <FormControl isInvalid={!!displayError} isRequired={isRequired} className={`form-control-${props.size || "md"}`}>
      {label && <FormLabel {...(props.size && { mb: 1, fontSize: props.size })}>{label}</FormLabel>}
      <InputGroup>
        {inputLeftAddon && (
          <InputLeftAddon
            children={inputLeftAddon}
            {...(inputLeftAddonBg && { bg: inputLeftAddonBg, borderColor: "blackAlpha.200", marginInlineEnd: 0, px: 3 })}
          />
        )}
        {inputLeftElement && <InputLeftElement>{inputLeftElement}</InputLeftElement>}
        <Input {...getInputProps()} {...props} ref={forwardRef} autoComplete={autoComplete} onFocus={handleFocus} onBlur={handleBlur} />
        {inputRightAddon && <InputRightAddon children={inputRightAddon} />}
        {inputRightElement && <InputRightElement>{inputRightElement}</InputRightElement>}
      </InputGroup>
      <FieldFooter error={displayError} helper={helper} />
      {/* Here we conditionally pass the error to FieldFooter only when the input is focused */}
    </FormControl>
  );
}
